<template>
<div>
  <el-card class="user-panel" shadow="never" :body-style="{ width: '100%' }">
    <el-row class="home-page" :gutter="20" type="flex">
      <el-col :span="18" class="info">
          <div class="avatar">
<!--            <el-avatar :size="80" :src="userInfo.avatar"></el-avatar>-->
            <avue-avatar :size="80" :style="{backgroundColor: color, verticalAlign: 'middle'}">
              {{userInfo.nick_name}}</avue-avatar>
          </div>
          <div class="text-box">
            <div class="tip"> {{userInfo.tip}} </div>
            <div>{{userInfo.role_name}} | 上次登录时间: {{userInfo.login_date}} | 上次登录IP:{{userInfo.login_ip}}</div>
          </div>
      </el-col>
      <el-col :span="6" class="extra">
      </el-col>
    </el-row>
  </el-card>
</div>
</template>
<script>
import Home from './home.js';

export default Home;
</script>
